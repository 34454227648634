const socialsAdapter = (data) => {
  return {
    link: {
      title: data.link.title,
      url: data.link.url,
    },
  };
};

export default ({ data }) => {
  return {
    text: data.text,
    icon: data.icon,
    socials: data.socials?.map(socialsAdapter),
  };
};
